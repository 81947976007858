import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class TopBar extends Component {
    render() {
        return (
            <div id="top-bar">
                <div id="top-bar-inner" className="container">
                    <div className="top-bar-inner-wrap">
                        <div className="top-bar-content">
                            <div className="inner">
                                <span className="address content">H- 33, R- 13 ,Rupnagar, Mirpur,Dhaka</span>
                                <span className="phone content">(+880) 1320384380</span>
                                <span className="time content">Sat - Thur: 10am - 8.30pm</span>
                            </div>                            
                        </div>
                        <div className="top-bar-socials">
                            <div className="inner">
                                <span className="text">Follow us:</span>
                                <span className="icons">
                                    <Link to="facebook.com"><i className="fa fa-facebook" /></Link>
                                    <Link to="facebook.com"><i className="fa fa-twitter" /></Link>
                                    <Link to="facebook.com"><i className="fa fa-pinterest-p" /></Link>
                                    <Link to="facebook.com"><i className="fa fa-linkedin" /></Link>
                                </span>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        );
    }
}

export default TopBar;