const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
        namesub: [
            {
                id: 1,
                sub: 'Home',
                links: '/',
                
            }
        ]
    },
    {
        id: 2,
        name: 'About Us',
        namesub: [
            {
                id: 1,
                sub: 'About Us',
                links: '/about-us'
            },
            {
                id: 2,
                sub: 'Team',
                links: '/about-team'
            },
            {
                id: 3,
                sub: 'Carees',
                links: '/about-carees'
            }
        ],
    },
    {
        id: 3,
        name: 'Product',
        namesub: [
            {
                id: 1,
                sub: 'Products',
                links: '/project'
            }
        ],
    },
    {
        id: 4,
        name: 'Contact',
        namesub: [
            {
                id: 1,
                sub: 'Contact Us',
                links: '/contact-01'
            }
            
        ],
    }
]

export default menus;