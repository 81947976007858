import React, { Component } from 'react';

class Accordion extends Component {
    render() {
        return (
            <div className="col-md-6">
                <div className="themesflat-spacer clearfix" data-desktop={0} data-mobile={0} data-smobile={35} />
                <div className="themesflat-content-box" data-margin="3px 0 0 10px" data-mobilemargin="0 0 0 0">
                    <div className="themesflat-accordions style-2 has-icon icon-left iconstyle-2 clearfix">
                        <div className="accordion-item active">
                            <h3 className="accordion-heading"><span className="inner">Oil Consumption ?</span></h3>
                            <div className="accordion-content">
                                <div> Reduces oil consumption and maximizes oil drain intervals</div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h3 className="accordion-heading"><span className="inner">Quick Start?</span></h3>
                            <div className="accordion-content">
                                <div>Smooth quick start in low -temperature and rapid acceleration</div>
                            </div>
                        </div>
                        <div className="accordion-item ">
                            <h3 className="accordion-heading"><span className="inner"> Corrosion Protection ?</span></h3>
                            <div className="accordion-content">
                                <div>High wear and corrosion protection of the engine and gear box</div>
                            </div>
                        </div>
                        <div className="accordion-item ">
                            <h3 className="accordion-heading"><span className="inner"> Clutch Performance ?</span></h3>
                            <div className="accordion-content">
                                <div>Provides smooth clutch performance</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Accordion;